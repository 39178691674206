import React from "react";
import { IButton } from "../../utils/types";
import { Link } from 'react-router-dom';

const ButtonOutline2: React.FC<IButton> = ({ onClick, text, to }) => {
  return (
    <button className="button button-outline" onClick={onClick}>
      <Link rel="prefetch" to={to}>{text}</Link>
    </button>
  );
};

export default ButtonOutline2;
