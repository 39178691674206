import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BrandWhite: React.FC = () => {
  return (
    <LazyLoadImage
      src={"https://tekfall-home.s3.amazonaws.com/tekfallsupreme_logo_brandw.png"}
      style={{
        width: "100%",
      }}
      alt="TEKFALL SUPREME"
    />
  );
};

export default BrandWhite;
