import React from "react";
import FootItem from "../../atoms/FootItem";
import Conversation from "../../organisms/conversation";
import { LazyLoadImage } from "react-lazy-load-image-component";

const FooterNew: React.FC = () => {
  return (
    <section className="bg-gey-black">
      <div className="w-11/12 md:w-11/12 mx-auto py-10">
        <div className="flex flex-col gap-10">
          <section className="flex gap-8 flex-row flex-wrap  justify-between">
            <ul className="flex gap-4 flex-col flex-wrap text-white ">
              <FootItem text={"TekFall Supreme"} url={"/"} />
              <FootItem text={"Home"} url={"/"} />
              <FootItem text={"News"} url={"/blog"} />
              <FootItem text={"Social Wall"} url={"/"} />
              {/* <FootItem text={"DAPS"} url={"/daplearn"} /> */}
            </ul>
            <ul className="flex gap-4 flex-col flex-wrap text-white ">
              <FootItem text={"MetaMotionAI"} url={"/mocap"} />
              <FootItem text={"Pre-Order"} url={"/preorderform"} />
              <FootItem text={"Digital Double"} url={"/doublelearn"} />
              <FootItem text={"Contact Us"} url={"/contact"} />
            </ul>
            <ul className="flex gap-4 flex-col flex-wrap text-white ">
              <FootItem text={"About Us"} url={"/about"} />
              <FootItem text={"Privacy & Policy"} url={"/privacy"} />
              <FootItem text={"Term of Service"} url={"/privacy"} />
            </ul>
          </section>
          <hr className="text-white bg-white" />
          <section className="flex flex-col md:flex-row justify-between gap-8">
            <Conversation />
            <div className="flex-grow-0 flex-shrink w-full md:w-4/12 lg:w-2/12 xl:w-2/12">
              {/* <FooterMap /> */}
              <LazyLoadImage
                // style={{maxWidth: "200px"}}
                src="https://tekfall-home.s3.amazonaws.com/ESRB+Video+game+logo.png"
                alt="entertainment"
              />
            </div>
          </section>
          <hr className="text-white bg-white" />
          <section className="flex flex-col gap-8 items-start">
            {/* <LazyLoadImage
              style={{maxWidth: "200px"}}
              src="https://res.cloudinary.com/skiltime/image/upload/v1689425355/ESRB_Video_game_logo_fq2tnf.png"
              alt="entertainment"
            /> */}
            <span className={`text-white self-center text-sm `}>
              © {2023} <a href="https://flowbite.com/">TEKFALL SUPREME™</a>. All
              Rights Reserved.
            </span>
          </section>
        </div>
      </div>
    </section>
  );
};

export default FooterNew;
