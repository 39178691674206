import { TDataImageAuction, TDataVideoAuction } from "../types";

export const dataImageAuction: TDataImageAuction = [
  {
    author: "Suzan",
    authorVerified: true,
    title: "Takedowns",
    bidPrice: 0.00234,
    image: "/nft/wrestling4.png",
    date: "15 october 2021",
    expired: 1639152000,
  },
  {
    author: "Diana",
    authorVerified: true,
    title: "Counter attack",
    bidPrice: 0.00981,
    image: "/nft/nft-2.jpg",
    date: "15 october 2021",
    expired: 1639152000,
  },
  {
    author: "Hoppermen",
    authorVerified: true,
    title: "Throws",
    bidPrice: 0.00367,
    image: "/nft/nft-3.jpg",
    date: "15 october 2021",
    expired: 1639152000,
  },
  {
    author: "Wesley",
    authorVerified: false,
    title: "Escape",
    bidPrice: 0.00367,
    image: "/nft/nft-4.jpg",
    date: "15 october 2021",
    expired: 1639152000,
  },
  {
    author: "Mack",
    authorVerified: false,
    title: "Escape",
    bidPrice: 0.00367,
    image: "/nft/nft-4.jpg",
    date: "15 october 2021",
    expired: 1639152000,
  },
];

export const dataVideoAuction: TDataVideoAuction = [
  {
    author: "Diana",
    authorVerified: true,
    title: "Takedowns",
    bidPrice: 0.00234,
    video:"https://tekfall-home.s3.amazonaws.com/Compressed+VID_29350131_171033_998_compressed.mp4",
    vId:'v1689323071',
    date: "15 october 2021",
    expired: 1639152000,
  },
  {
    author: "James",
    authorVerified: true,
    title: "Counter attack",
    bidPrice: 0.00981,
    video:
      "https://tekfall-home.s3.amazonaws.com/Compressed+VID-20211201-WA0000_compressed+(1).mp4",
    date: "15 october 2021",
    vId:"v1689323083",
    expired: 1639152000,
  },
  {
    author: "Hoppermen",
    authorVerified: true,
    title: "Throws",
    bidPrice: 0.00367,
    video:"https://tekfall-home.s3.amazonaws.com/Compressed+VID-20220102-WA0002_compressed_compressed_trimmed_compressed.mp4",
    date: "15 october 2021",
    vId:"v1689323084",
    expired: 1639152000,
  },
  {
    author: "Wesley",
    authorVerified: false,
    title: "Escape",
    bidPrice: 0.00367,
    video:
      "https://tekfall-home.s3.amazonaws.com/Compressed+VID_29350131_171033_998_compressed.mp4",
    date: "15 october 2021",
    vId:'v1689323083',
    expired: 1639152000,
  },
  {
    author: "Mack",
    authorVerified: false,
    title: "Escape",
    bidPrice: 0.00367,
    video:
      "https://tekfall-home.s3.amazonaws.com/Compressed+VID-20220102-WA0002_compressed_compressed_trimmed_compressed.mp4",
    date: "15 october 2021",
    vId:'v1689323073',
    expired: 1639152000,
  },
  {
    author: "Mack",
    authorVerified: false,
    title: "Escape",
    bidPrice: 0.00367,
    video:
      "https://tekfall-home.s3.amazonaws.com/Compressed+VID-20211201-WA0000_compressed+(1).mp4",
    date: "15 october 2021",
    vId:'v1689323070',
    expired: 1639152000,
  },
];
