import React  from "react";
import { dataVideoAuction } from "../../utils/data";
import CardVideoAuction from "../../atoms/card/CardVideoAuction";
// import { DarkModeContext } from "../../DarkModeProvider";
import ButtonOutline2 from "../../atoms/control/ButtonOutline2";

const ListAuction: React.FC = () => {
  // const { isVideoCollection, toggleCollection } = useContext(DarkModeContext);
  return (
    <>

      <h4 
        id="textMobile" 
        style={{color: ' #66ff66'}} 
        className="animate-bounce italic text-8xl text-center font-extrabold">
        SOCIAL WALL
      </h4>
      <div
        id="unique-flex-row"
        className="flex flex-row bg-black bg-bg-lat flex-nowrap bg-cover py-20  overflow-x-hidden scroll-snap-x"
      >
        {dataVideoAuction.map((data, i) => (
              <div
                key={i}
                className="flex-grow-0 flex-shrink-0 w-full md:w-6/12 xl:w-3/12 p-3 scroll-snap-start"
              >
                <CardVideoAuction
                  author={data.author}
                  authorVerified={data.authorVerified}
                  title={data.title}
                  vId={data?.vId}
                  bidPrice={data.bidPrice}
                  video={data.video}
                  date={data.date}
                  expired={data.expired}
                />
              </div>
            ))}
          {/* dataImageAuction.map((data, i) => (
              <div
                key={i}
                className="flex-grow-0 flex-shrink-0 w-full md:w-6/12 xl:w-3/12 p-3 scroll-snap-start"
                data-aos="fade-left"
              >
                <CardImageAuction
                  author={data.author}
                  authorVerified={data.authorVerified}
                  title={data.title}
                  bidPrice={data.bidPrice}
                  image={data.image}
                  date={data.date}
                  expired={data.expired}
                />
              </div>
            )) */}
      </div>
      <section className="flex flex-col w-full justify-center items-center mb-10 gap-8 text-center">
        <div className="flex md:w-3/4 flex-col text-2xl gap-4">
          <ButtonOutline2 text={"COMING SOON..."} to={'/comingsoon'}/>
        </div>
        <div className="flex md:w-3/4 flex-col gap-2 text-center text-white">
          <p>
            Post your favorite moves & taunts that you want in Tekfall
            Supreme. Connect with your friends & share your wrestling
            highlights. Tekfall members can earn rewards and receive
            BATS-TOI Goodies which unlock exclusive in-game surprises and
            real-world merch and experiences.
          </p>
        </div>
      </section>
    </>
  );
};

export default ListAuction;
