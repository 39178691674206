
import React from "react";

import StackCard from "../../atoms/card/StackCard";

const CardThree: React.FC = () => {

  return (
        <div className="w-full h-screen" data-aos="fade-right">
          <div className="flex flex-col items-center justify-center">
            <StackCard
              imgUrl={"https://tekfall-home.s3.amazonaws.com/HighresScreenshot00027_1_h7j1ju.png"}
              num={"03"}
              titText="BUILD YOUR COMMUNITY"
              text="Connect with friends to create & join teams to compete against other players."
            />
          </div>
        </div>
  );
}
export default CardThree;