import React, { useState } from "react";
import BrandWhite from "../../components/atoms/brandwhite";
import { BsCart4 } from "react-icons/bs";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import CheckboxInput from "../../components/atoms/Inputs/CheckBoxInput";
import RadioBoxInput from "../../components/atoms/Inputs/RadioBoxInput";
import BasicButton from "../../components/atoms/control/BasicButton";
import axios from "axios";

type IProps = {
  onClose: any
}

// Modal component
const Modal: React.FC<IProps> = ({ onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-700 bg-opacity-50">
      <div className="bg-white text-black p-8 rounded-lg shadow-lg">
        <p>You must be over 14 years old to proceed with the preorder.</p>
        <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

const Preorderform = () => {
  const [gameSystem, setGameSystem] = useState<string[]>([]);
  const [age, setAge] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [classification, setClassification] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  
  const areAllCheckboxesChecked = (checkboxValues: string[]): boolean => {
    return checkboxValues.length > 0;
  };

  const isFormValid = () => {
    return (
      areAllCheckboxesChecked(gameSystem) &&
      age !== "" &&
      gender !== "" &&
      areAllCheckboxesChecked(classification)
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isFormValid()) return;

    // if (age === "UNDER 14") {
    //   // Open the modal if age is under 14
    //   setIsModalOpen(true);
    // } else {
      const formData = {
        gameSystem,
        age,
        gender,
        classification,
      };
  
      try {
        // Make the POST request using Axios
        const response = await axios.post('https://tekfall-supreme-backened.vercel.app/cart/pre-order', formData);
        console.log('Response:', response);
        // After successful completion, navigate to "/order"
        response && navigate("/order");
        console.log("Form submitted successfully!");
      } catch (error) {
        console.log('Error:', error);
        toast.error('An error occurred. Please try again later.', {
          duration: 3000,
          position: 'bottom-center',
          style: {
            backgroundColor: '#353535',
            color: '#fff'
          }
        });
      }
    // }
  };
  return (
    <>
      <div className="bg-join-bg bg-cover">
        <section className="w-11/12 md:w-11/12 mx-auto py-20">
          <div className="flex flex-col md:flex-row gap-8 text-white">
            <div
              className="w-full flex flex-col justify-end items-center bg-hero-bg bg-center bg-cover"
              style={{ minHeight: "500px" }}
            >
              <div className="w-3/4 z-50 pb-16">
                <BrandWhite />
              </div>
            </div>
            <div className="flex flex-col w-full gap-8">
              <p className="text-xl font-bold">
                TekFall Supreme BETA EARLY ACCESS will be available WINTER 2023.
                Official game will be SPRING 2024. Exact dates for the gameplay
                will be given 30-45 days within game drop.
              </p>
              <p>Please answer the following questions:</p>
              
              {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}

              <form
                method='POST'
                name='form'
                onSubmit={handleSubmit}
              >
                <CheckboxInput
                  label="Which Game System Do You Use? Check All That Apply"
                  number={'1.'}
                  options={[
                    { label: "PC", value: "PC" },
                    { label: "PS4", value: "PS4" },
                    { label: "XBOX", value: "XBOX" },
                    { label: "STEAM", value: "STEAM" },
                  ]}
                  selectedValues={gameSystem}
                  onInputChange={(values: string[]) => setGameSystem(values)}
                />

                <RadioBoxInput
                  label="Age?"
                  number={'2.'}
                  options={[
                    "UNDER 14",
                    "14-17",
                    "18-24",
                    "25-34",
                    "35-44",
                    "45-54",
                    "55-64",
                    "65 AND OVER",
                  ]}
                  selectedValue={age}
                  onInputChange={(value: string) => setAge(value)}
                />

                <RadioBoxInput
                  label="Gender?"
                  number={'3.'}
                  options={["MALE", "FEMALE", "OTHER", "PREFER NOT TO SAY"]}
                  selectedValue={gender}
                  onInputChange={(value: string) => setGender(value)}
                />

                <CheckboxInput
                  label="Please Classify Yourself? Check All That Apply"
                  number={'4.'}
                  options={[
                    { label: "WRESTLER/ATHLETE", value: "WRESTLER/ATHLETE" },
                    { label: "COACH", value: "COACH" },
                    { label: "FAN", value: "FAN" },
                    { label: "GAMER", value: "GAMER" },
                  ]}
                  selectedValues={classification}
                  onInputChange={(values: string[]) => setClassification(values)}
                />

                <div className='w-full py-5 mx-auto'>
                  {/* <Terms /> */}
                  <BasicButton type='submit' disabled={!isFormValid()}>
                    <div className="flex items-center gap-2">
                      <BsCart4 className="text-white" />
                      PRE ORDER NOW
                    </div>
                  </BasicButton>
                </div>

                <Toaster />
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Preorderform;
