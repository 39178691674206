
import React from "react";
import SecondStackCard from "../../atoms/card/SecondStackCard";

const CardTwo: React.FC = () =>  {

  return (  

        <div className="w-10/12 h-screen mt-12 relative" data-aos="fade-left">
          <div className="flex flex-col items-center justify-center">
            <SecondStackCard
              img1={"https://res.cloudinary.com/skiltime/image/upload/v1687629765/Tekfall_Supreme/1687629691526-tbrender_016-1.png"}
              vid={'https://tekfall-home.s3.amazonaws.com/Compressed+DAP+SECTION_+Unlock+Special+Access_MercadoIII_compressed.mp4'}
              num={"02"}
              titText="UNLOCK SPECIAL ACCESS"
              text="Collect game packs that unlock real-world items & connected experiences."
            />
          </div>
        </div>
  );
}
export default CardTwo;