import React, { useState, useEffect } from "react";
import NavBrand from "../../molecules/navigation/NavBrand";
import NavButton from "../../molecules/navigation/NavButton";
import NavToggle from "../../molecules/navigation/NavToggle";
// import { DarkModeContext } from "../../DarkModeProvider";
import { BsPersonCircle } from "react-icons/bs";
import MobileNavbar from "../mobilenavbar";
import NavItem from "../../atoms/NavItem";
import { LazyLoadImage } from "react-lazy-load-image-component";
import NavBtn from "../../molecules/navigation/NavBtn";
import { Link } from 'react-router-dom';

const Navigation: React.FC = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  // const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      setScrollPosition(currentPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isScrollPastThreshold = scrollPosition >= 800;

  return (
    <>
    <p className="hidden">{isScrollPastThreshold ? 'scroll' : ''}</p>
      <div className="relative py-2 transition-all duration-300 z-50 bg-white">
        <div className="w-11/12 md:w-11/12 mx-auto">
          <div className="flex flex-row  justify-between items-center">
            <div className="flex flex-row items-center">
            <NavBrand title="TFSLOGO" icon={"https://tekfall-home.s3.amazonaws.com/tekfall+supreme_logo_black-resize.png"} />

            <ul className="hidden lg:flex gap-4 flex-row ml-4 text-black ">
              <NavItem to={"/comingsoon"} text="Social Wall" dropdownItems="" />
              <NavItem to={"/mocap"} text="Metamotion AI" dropdownItems="" />
              {/* <NavItem to={"/daplearn"} text="Dap" dropdownItems="" /> */}
              <NavItem to={"/doublelearn"} text="Digital Double" dropdownItems="" />
            </ul>
            </div>
            <div className="flex gap-4 items-center">
              <Link rel="prefetch" to={"https://batstoi.com"} target="_blank" className={`cursor-pointer`}>
                <LazyLoadImage src="https://tekfall-home.s3.amazonaws.com/batt.png" className="h-8 w-8 hidden md:block" />
              </Link>
              <a href="https://app.tekfallsupreme.com" rel="noreferrer" target="_blank">
                <BsPersonCircle className="text-4xl sm:hidden" />
              </a>
              <NavButton />
              <NavBtn />
              <NavToggle btnClick={() => setIsActive(true)} />
            </div>
          </div>
        </div>
      </div>
      <MobileNavbar isActive={isActive} btnClick={() => setIsActive(false)} />
    </>
  );
};

export default Navigation;
