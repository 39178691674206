import React from "react";
// import { animateScroll as scroll } from "react-scroll";
import { Link } from 'react-router-dom';
type IProps = {
  to: any,
  text: any,
  dropdownItems: any
}

const NavItem: React.FC<IProps> = ({ to, text }) => {
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const scrollToLocation = () => {
  //   scroll.scrollTo(`#${to}` as any, {
  //     smooth: true,
  //     offset: -50,
  //   });
  // };

  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };

  return (
    <li
      className="relative mr-3"
      // onMouseEnter={() => setIsDropdownOpen(true)}
      // onMouseLeave={() => setIsDropdownOpen(false)}
      // onClick={toggleDropdown}
    >
      <Link
        rel="prefetch"
        to={to}
        // onClick={scrollToLocation}
        className="text-black font-bold hover:text-pink cursor-pointer duration-300 uppercase  mb-3"
      >
        {text}
      </Link>
      {/* {dropdownItems?.length > 0 && (
        <button className="ml-1 focus:outline-none">
          <BsChevronDown
            className={`h-4 w-4 transition-transform duration-300 font-bold hover:text-pink transform ${
              isDropdownOpen ? "rotate-180" : ""
            }`}
          />
        </button>
      )}
      <div
        style={{ width: "130px" }}
        className={`absolute top-full left-0 ${
          isDropdownOpen ? "bg-white shadow-lg" : ""
        } p-2 rounded-md z-10 overflow-hidden transition-max-height duration-300 ${
          isDropdownOpen ? "max-h-64" : "max-h-0"
        }`}
      >
        {dropdownItems && (
          <ul style={{ display: dropdownItems ? "block" : "none" }}>
            {dropdownItems.map(
              (
                item: { to: any; text: any },
                index: React.Key | null | undefined
              ) => (
                <li key={index}>
                  <Link
                    to={item.to}
                    spy={true}
                    smooth={true}
                    duration={300}
                    onClick={scrollToLocation}
                    className="block cursor-pointer text-gray-800 hover:text-pink duration-300"
                  >
                    {item.text}
                  </Link>
                </li>
              )
            )}
          </ul>
        )}
      </div> */}
    </li>
  );
};

export default NavItem;
